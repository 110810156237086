<script>
export default {
	name: 'ZhuCard',
	props: {
		title: String | Array,
		titleCss: Object
	},
	data() {
		return {
			index_: 0
		}
	},
	methods: {
		onItem(val) {
			this.index_ = val
			this.$emit('onItem', val)
		}
	}
}
</script>

<template>
	<div class="zhu-card">
		<div class="zhu-card-title">
			<slot name="header">
				<div v-if="Array.isArray(title)" class="zhu-card-title">
					<div v-for="(item, index) in title" :style="{borderRadius: index === 0 ? '8rem 0 8rem 0' : index === title.length - 1 ? '0 0 8rem 8rem' : '0 0 8rem 8rem', backgroundColor: index === index_ ? '#EAF3FF' : '#F9F9F9'}" class="title-text" @click="onItem(index)">
						<span :key="index" :style="{...titleCss, color: index === index_ ? '#3C3C3C' : '#C2C2C4'}">{{ item }}</span>
					</div>
				</div>
				<div v-else class="title-text" style="border-radius: 8rem 0 0 0">
					<span :style="titleCss">{{ title }}</span>
				</div>
				<slot name="right"></slot>
			</slot>
		</div>
		<div class="zhu-card-content">
			<slot></slot>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.zhu-card {
	border: 1rem solid #E1E3E7;
	border-radius: 8rem;

	&:not(:last-child) {
		margin-bottom: 80rem;
	}

	&-title {
		display: flex;
		font-size: 16rem;
		font-weight: 500;
		height: 32rem;
		line-height: 32rem;
		margin-bottom: 15rem;

		.title-text {
			background-color: #EAF3FF;
			color: #3C3C3C;
			padding: 0 38rem;
		}
	}

	&-content {
		color: #333;
		font-size: 14rem;
		font-weight: 400;
		line-height: 28rem;
		padding: 10rem;
	}
}
</style>
